exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-acerca-tsx": () => import("./../../../src/pages/acerca.tsx" /* webpackChunkName: "component---src-pages-acerca-tsx" */),
  "component---src-pages-atm-terminos-tsx": () => import("./../../../src/pages/atm/terminos.tsx" /* webpackChunkName: "component---src-pages-atm-terminos-tsx" */),
  "component---src-pages-atm-tsx": () => import("./../../../src/pages/atm.tsx" /* webpackChunkName: "component---src-pages-atm-tsx" */),
  "component---src-pages-bitcoin-comprar-tsx": () => import("./../../../src/pages/bitcoin/comprar.tsx" /* webpackChunkName: "component---src-pages-bitcoin-comprar-tsx" */),
  "component---src-pages-bitcoin-vender-tsx": () => import("./../../../src/pages/bitcoin/vender.tsx" /* webpackChunkName: "component---src-pages-bitcoin-vender-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-dai-vender-tsx": () => import("./../../../src/pages/dai/vender.tsx" /* webpackChunkName: "component---src-pages-dai-vender-tsx" */),
  "component---src-pages-ether-comprar-tsx": () => import("./../../../src/pages/ether/comprar.tsx" /* webpackChunkName: "component---src-pages-ether-comprar-tsx" */),
  "component---src-pages-ether-vender-tsx": () => import("./../../../src/pages/ether/vender.tsx" /* webpackChunkName: "component---src-pages-ether-vender-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-litecoin-vender-tsx": () => import("./../../../src/pages/litecoin/vender.tsx" /* webpackChunkName: "component---src-pages-litecoin-vender-tsx" */),
  "component---src-pages-oportunidades-tsx": () => import("./../../../src/pages/oportunidades.tsx" /* webpackChunkName: "component---src-pages-oportunidades-tsx" */),
  "component---src-pages-otc-tsx": () => import("./../../../src/pages/otc.tsx" /* webpackChunkName: "component---src-pages-otc-tsx" */),
  "component---src-pages-pagos-tsx": () => import("./../../../src/pages/pagos.tsx" /* webpackChunkName: "component---src-pages-pagos-tsx" */),
  "component---src-pages-tether-comprar-tsx": () => import("./../../../src/pages/tether/comprar.tsx" /* webpackChunkName: "component---src-pages-tether-comprar-tsx" */),
  "component---src-pages-tether-vender-tsx": () => import("./../../../src/pages/tether/vender.tsx" /* webpackChunkName: "component---src-pages-tether-vender-tsx" */),
  "component---src-pages-tiendas-tsx": () => import("./../../../src/pages/tiendas.tsx" /* webpackChunkName: "component---src-pages-tiendas-tsx" */),
  "component---src-templates-av-tsx": () => import("./../../../src/templates/av.tsx" /* webpackChunkName: "component---src-templates-av-tsx" */),
  "component---src-templates-pdv-tsx": () => import("./../../../src/templates/pdv.tsx" /* webpackChunkName: "component---src-templates-pdv-tsx" */),
  "component---src-templates-template-tsx": () => import("./../../../src/templates/template.tsx" /* webpackChunkName: "component---src-templates-template-tsx" */)
}

